
.btn{
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.item{
    height: 350px;
    overflow: hidden;
    background: #000;
    padding: 0;
    padding: 0 300px 0 0;
    position: relative;
    
}

.item .itemimage {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 10px solid #fff;
}

.item .itemimage>img {
    display: block;
    height: auto;
    min-height: 100%;
    position: relative;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    width: calc(100% + 1px);
    margin-left: -1px;
}

.item .itemimage .rerainfo {
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    font-size: 11px;
    line-height: 14px;
    text-align: right;
    color: #fff;
    background: rgba(19,17,40,.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carousel-caption {
    width: 300px;
    height: 100%;
    background: #00977e;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px 15px 15px 20px;
    text-align: left;
    text-shadow: none;
    font-weight: 500;
}

.carousel-caption .offerbox {
    width: 100%;
    padding: 10px 0;
    margin: 0 0 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.carousel-caption p {
    font-size: 13px;
    line-height: 18px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
}
.carousel-caption .ellipsis {
    color: #fff;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
}
.btn{
  background: hsla(0,0%,100%,0);
  padding: 10px 12px;
  font-size: 12px;
  line-height: 100%;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  position: absolute;
  right: 15px;
  bottom: 15px;
  text-transform: uppercase;
  font-family: Roboto-Medium,sans-serif;
  transition: all .3s ease;
  cursor: pointer;
}