.main-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0.5rem;
  position: fixed;
  top: 0px;
  z-index: 1000;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(20, 38, 132, 0.1);
  padding: var(--bs-gutter-y) calc(var(--bs-gutter-x) * 0.5);
}
.container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}
.image-container {
  display: flex;
  flex: 1;
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

.nav-links > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}
.nav-link a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2e313a;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
  position: relative;
}
.nav-link:hover a {
  color: #f09922;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.nav-link {
  padding: 0.5rem 1rem;
}
.nav-link[data-active='true'] a {
  color: #f09922;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.nav-link[data-active='true'] > a:before {
  content: '.';
  font-size: 38px;
  position: absolute;
  left: -14px;
  display: flex;
  align-items: center;
  top: -10px;
  color: #129a83;
}

@media (min-width: 992px) {
  .container {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
}

.logo-container,
.logo-container-mobile {
  height: 80px;
  display: flex;
  align-items: center;
  width: 174px;
  cursor: pointer;
}
.logo-container-mobile {
  display: none;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-links {
  display: flex;
  flex: 1;
}

.svg-icon {
  fill: #ffffff;
  width: 15px;
  path: {
    fill: #ffffff;
  }
}
.hamburger-icon {
  width: 1.6rem;
  height: 3px;
  border-radius: 3px;
  background-color: #129a83;
  position: relative;
  z-index: 1001;
  transition: 0.5s;
  cursor: pointer;
}
.hamburger-icon::after,
.hamburger-icon::before {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: #129a83;
  border-radius: 3px;
  transition: 0.5s;
}
.hamburger-icon::before {
  cursor: pointer;
  transform: translateY(-7px);
}
.hamburger-icon::after {
  cursor: pointer;
  transform: translateY(7px);
}

.hamburger-container[data-open='true'] .hamburger-icon {
  background-color: transparent;
  &::after {
    transform: translateY(0) rotate(45deg);
  }
  &::before {
    transform: translateY(0) rotate(-45deg);
  }
}
.hamburger-container {
  display: none;
}

@media screen and(max-width:835px) {
  .image-container {
    position: fixed;
    height: calc(100vh - 0rem);
    top: 0rem;
    right: 0;
    width: 70%;
    background-color: #ffffff;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: 0.65s;
    padding-top: 3rem;
    box-shadow: 0px 0px 20px 0px #ddd;

    .logo-container {
      display: none;
    }
  }
  .logo-container-mobile {
    display: block;
    cursor: pointer;
  }
  .btn-container {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
  }
  .nav-links {
    width: 100%;
    display: block;
    flex: 0;
  }
  .nav-links ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .nav-link {
    width: 100%;
    padding: 0.5rem 1rem;
  }
  .nav-link a {
    width: 100%;
    line-height: 1;
    padding: 1rem 1rem;
    border-bottom: 1px solid #c7ddeb;
  }
  .nav-link[data-active='true'] > a:before {
    left: 0px;
    top: -5px;
  }
  .hamburger-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 31px;
  }
  .hamburger-container[data-open='true'] ~ .image-container {
    transform: translateX(0);
  }
}
