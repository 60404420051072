.button {
  --btn-radius: 0px;
  --btn-svg-bg: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-left: 20px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  cursor: pointer;
  color: var(--btn-color);
  border: 1px solid var(--btn-border);
  background: var(--btn-bg);
  border-radius: var(--btn-radius);
  svg {
    fill: var(--btn-svg-bg);
    width: 15px;
    path: {
      fill: var(--btn-svg-bg);
    }
  }
}

.primary-button:hover {
  --btn-bg: #129a83;
  --btn-border: #129a83;
  --btn-color: #fff;
}
.primary-outline-button:hover {
  --btn-bg: transparent;
  --btn-border: #129a83;
  --btn-color: #129a83;
  --btn-svg-bg: #129a83;
}
.primary-button,
.primary-outline-button {
  --btn-border: #f09922;
  --btn-color: #fff;
  --btn-svg-bg: #ffffff;
}

.primary-button {
  --btn-radius: 50px;
  --btn-bg: #f09922;
}

.secondary-button {
  --btn-bg: #f09922;
  --btn-border: #f09922;
  --btn-color: #fff;
  --btn-svg-bg: #ffffff;
}
.secondary-button:hover {
  --btn-bg: transparent;
  --btn-border: #fff;
}
.button-width-full {
  width: 100%;
}
.primary-outline-button {
  --btn-bg: transparent;
  --btn-color: #f09922;
  --btn-svg-bg: #f09922;
}

.tertiary-button {
  --btn-bg: #f09922;
  --btn-border: #f09922;
  --btn-color: #fff;
  --btn-svg-bg: #ffffff;
  --btn-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}
.tertiary-button:hover {
  --btn-bg: #129a83;
  --btn-border: #129a83;
  --btn-color: #fff;
  --btn-svg-bg: #ffffff;
}
