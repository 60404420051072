.heading{
    font-size: 20px;
    font-family: Roboto;
    color: #282828;
    font-weight: 600;
   
}
.dashedContaibner{
     border-bottom: 1px dashed #00977e;
     background-color: #fff;
}