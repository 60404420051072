.footer-container {
  background-color: #f7fbfc;
  border-bottom: 1px solid rgba(46, 49, 58, 0.2);
  padding: var(--sec-gap);
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0.5rem;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}
.container {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .container {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
}

.footer-img {
  margin-bottom: 1rem;
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #129a83;
}
.text-start {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  color: #2e313a;
  text-align: left;
}

.list-style {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-style li a {
  margin-bottom: 5px;
  font-size: 14px;
  color: #2e313a;
  display: block;
}
.list-style li a:hover {
  color: #129a83;
}

@media screen and (max-width: 575px) {
  .text-start {
    font-size: 12px;
  }
  .card-title,
  .text-start {
    text-align: center;
  }
  .list-style,
  .list-style li {
    width: 100%;
    text-align: center;
  }
}
