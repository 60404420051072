.label {
  display: block;
  text-align: left;
  color: #129a83;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.nice-select.form-control {
  float: unset;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  border: 0;
  color: #2e313a;
  padding: 0;
  padding-right: 20px;
}

.nice-select.open,
.nice-select:active,
.nice-select:focus {
  border-color: #999;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #2e313a;
  border: 0;
  padding: 0;
  height: 42px;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 40px;
  outline: 0;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.list {
  position: relative;
  padding-left: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nice-select:after {
  border-bottom: 0px solid #999;
  border-right: 0px solid #999;
  content: '';
  background-image: url(../../libs/shared//assets/images/select-arrow.png);
  display: block;
  background-repeat: no-repeat;
  height: 10px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 10px;
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  margin-top: -8px;
}

.nice-select .option {
  color: #2e313a;
}
.nice-select .nice-select .option.selected {
  background-color: #009688 !important;
  color: #000;
}
.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #f6f6f6;
}

.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #009688;
  color: #fff;
}

.nice-select .option.selected {
  font-weight: 700;
}

.nice-select .option {
  color: #2e313a;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: 0;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 575.98px) {
  .nice-select.form-control {
    font-size: 12px;
  }
}
