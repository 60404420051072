body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
a {
  text-decoration: none;
}
a,
a:hover,
a:focus {
  color: #129a83;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
:root {
  --thm-color: #3bb3c3;
  --orange-dark: #ee594d;
  --sec-gap: 50px 0;

  --screen-1: calc(100% * 1 / 12);
  --screen-2: calc(100% * 2 / 12);
  --screen-3: calc(100% * 3 / 12);
  --screen-4: calc(100% * 4 / 12);
  --screen-5: calc(100% * 5 / 12);
  --screen-6: calc(100% * 6 / 12);
  --screen-7: calc(100% * 7 / 12);
  --screen-8: calc(100% * 8 / 12);
  --screen-9: calc(100% * 9 / 12);
  --screen-10: calc(100% * 10 / 12);
  --screen-11: calc(100% * 11 / 12);
  --screen-12: calc(100% * 12 / 12);
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-left: auto;
  margin-right: auto;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
@media (min-width: 992px) {
  .container-fluid {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .container-fluid {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
}

.border-bottom {
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end {
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  flex: 0 0 auto;
}

.col-1 {
  width: var(--screen-1);
}
.col-2 {
  width: var(--screen-2);
}
.col-3 {
  width: var(--screen-3);
}
.col-4 {
  width: var(--screen-4);
}
.col-5 {
  width: var(--screen-5);
}
.col-6 {
  width: var(--screen-6);
}
.col-7 {
  width: var(--screen-7);
}
.col-8 {
  width: var(--screen-8);
}
.col-9 {
  width: var(--screen-9);
}
.col-10 {
  width: var(--screen-10);
}
.col-11 {
  width: var(--screen-11);
}
.col-12 {
  width: var(--screen-12);
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
//medium screen
@media (min-width: 768px) {
  .col-md-1 {
    width: var(--screen-1);
  }
  .col-md-2 {
    width: var(--screen-2);
  }
  .col-md-3 {
    width: var(--screen-3);
  }
  .col-md-4 {
    width: var(--screen-4);
  }
  .col-md-5 {
    width: var(--screen-5);
  }
  .col-md-6 {
    width: var(--screen-6);
  }
  .col-md-7 {
    width: var(--screen-7);
  }
  .col-md-8 {
    width: var(--screen-8);
  }
  .col-md-9 {
    width: var(--screen-9);
  }
  .col-md-10 {
    width: var(--screen-10);
  }
  .col-md-11 {
    width: var(--screen-11);
  }
  .col-md-12 {
    width: var(--screen-12);
  }
}

// large screen
@media (min-width: 992px) {
  .col-lg-1 {
    width: var(--screen-1);
  }
  .col-lg-2 {
    width: var(--screen-2);
  }
  .col-lg-3 {
    width: var(--screen-3);
  }
  .col-lg-4 {
    width: var(--screen-4);
  }
  .col-lg-5 {
    width: var(--screen-5);
  }
  .col-lg-6 {
    width: var(--screen-6);
  }
  .col-lg-7 {
    width: var(--screen-7);
  }
  .col-lg-8 {
    width: var(--screen-8);
  }
  .col-lg-9 {
    width: var(--screen-9);
  }
  .col-lg-10 {
    width: var(--screen-10);
  }
  .col-lg-11 {
    width: var(--screen-11);
  }
  .col-lg-12 {
    width: var(--screen-12);
  }
}
//extra large
@media (min-width: 1200px) {
  .col-xl-1 {
    width: var(--screen-1);
  }
  .col-xl-2 {
    width: var(--screen-2);
  }
  .col-xl-3 {
    width: var(--screen-3);
  }
  .col-xl-4 {
    width: var(--screen-4);
  }
  .col-xl-5 {
    width: var(--screen-5);
  }
  .col-xl-6 {
    width: var(--screen-6);
  }
  .col-xl-7 {
    width: var(--screen-7);
  }
  .col-xl-8 {
    width: var(--screen-8);
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: var(--screen-9);
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: var(--screen-10);
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: var(--screen-11);
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: var(--screen-12);
  }
}
