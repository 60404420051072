.container {
  min-height: calc(100vh - 90px);
  overflow: hidden;
}

.main-container {
  background-color: #fff;
  max-width: 100vw;
}
.slider-control-centerleft {
  left: -50px !important;
}
