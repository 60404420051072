.sub-title {
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  font-style: normal;
  line-height: 22px;
  text-transform: capitalize;
  color: #2e313a;
  margin-bottom: 1rem;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  text-transform: capitalize;
  color: #2e313a;
  margin-bottom: 1.5rem;
}
.form-check-input[type='checkbox'] {
  border-radius: 0.25em;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked {
  background-color: #f09922;
  border-color: #f09922;
}
.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  float: left;
  margin-right: 0.5rem;
}

.form-check-label {
  color: #f09922;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  text-transform: capitalize;
  font-weight: 400 !important;
  white-space: nowrap;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.card {
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(20, 38, 132, 0.15);
  border-radius: 10px;
  padding: 20px;
  button {
    margin-left: 0;
  }
}
