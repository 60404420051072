.label {
  display: block;
  text-align: left;
  color: #129a83;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #2e313a;
  border: 0;
  padding: 0;
  height: 42px;
  width: 100%;
}

.form-control::placeholder {
  color: rgba(46, 49, 58, 0.5);
  font-weight: 400;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
  .form-control {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #2e313a;
    border: 0;
    padding: 0;
    height: 42px;
    border: 1px solid #e9ecf8;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    width: 100%;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #fff;
    background-clip: padding-box;
  }
  .form-control::placeholder {
    color: rgba(46, 49, 58, 0.5);
  }
}
.img-fluid {
  position: absolute;
  top: 11px;
  right: 10px;
  width: 20px;
}

.nice-select {
  position: relative;
  width: 100%;
}
.list {
  padding-left: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  overflow: hidden;
  padding: 0;
  pointer-events: auto;
  position: absolute;
  top: 100%;
  left: 0;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  li {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: 0;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  li:hover {
    background-color: #009688;
    color: #fff;
  }
}
